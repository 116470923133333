import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { Grid, Box, Typography, Button } from "@mui/material"

import { SectionTitle } from "../SectionTitle"

import { StyledPageNotFound } from "./PageNotFound.styled"

import ImgLogo from "../../images/web-logo.svg"

const PageNotFound = ({ children }) => {
  return (
    <StyledPageNotFound>
      <Grid container>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <Box
                component="img"
                src={ImgLogo}
                alt="preferred networking logo"
                sx={{ width: "300px" }}
              />
            </Box>
            <SectionTitle align="center">404!</SectionTitle>
            <SectionTitle subtitle align="center">
              Oops! Something went wrong.
            </SectionTitle>
            <Typography align="center">
              We can't find the page your looking for.
            </Typography>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/")}
                sx={{ color: "white" }}
              >
                Take me Home
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </StyledPageNotFound>
  )
}

PageNotFound.propTypes = {
  children: PropTypes.node,
}

export default PageNotFound
